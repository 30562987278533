import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { AboutFlexbox, BenefitsFlexbox, TermitesFlexbox } from "../components/Flexbox";
import { CallToAction } from "../components/CallToAction";
import { PestCard } from "../components/Card";
import { CommonPestsGrid } from "../components/Grid";
import { IndexHero } from "../components/Hero";
import { ServiceCarousel, TestimonialsCarousel } from "../components/Carousel";


interface Props {
  data: any
};


const IndexPage = ({
  data
}: Props ) => {
  const benefits = data?.allPrismicBenefit?.edges || [];
  const pests = data?.allPrismicPest?.edges || [];
  const services = data?.allPrismicService?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const homepage = data?.prismicHomePage?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ homepage.title.text }
        description={ homepage.description.text }
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <IndexHero
            heading={ homepage.hero_heading.text }
            description={ homepage.hero_description.html }
            image={ homepage.hero_image.gatsbyImageData }
            alt={ homepage.hero_image.alt }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0"
      >
        <BenefitsFlexbox
          heading={ homepage.benefits_heading.text }
          description={ homepage.benefits_description.html }
          image={ homepage.benefits_image.gatsbyImageData }
          alt={ homepage.benefits_image.alt }
          nodes={ benefits }
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <AboutFlexbox
            direction="row"
            heading={ homepage.about_heading.text }
            description={ homepage.about_description.html }
            image={ homepage.about_image.gatsbyImageData }
            alt={ homepage.about_image.alt }
            imageMaxW={{
              base: '50%',
              sm: '320px',
              md: '100%',
              lg: '200px',
              xl: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading={ homepage.pests_heading.text }
            description={ homepage.pests_description.html }
          >
            { pests.map(( node: any, _index: number ) => (
              <PestCard
                key={ node.node.uid }
                data={ node.node.data }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading={ homepage.termites_heading.text }
            description={ homepage.termites_description.html }
            image={ homepage.termites_image.gatsbyImageData }
            alt={ homepage.termites_image.alt }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="50px 0 39px"
      >
        <ServiceCarousel
          heading={ homepage.services_heading.text }
          description={ homepage.services_description.html }
          nodes={ services }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ homepage.testimonials_heading.text }
          description={ homepage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query HomePageQuery {
    allPrismicBenefit {
      edges {
        node {
          _previewable
          data {
            benefit_heading {
              text
            }
            benefit_icon
            benefit_description {
              text
            }
          }
          uid
        }
      }
    }

    allPrismicPest(
      filter: {
        data: {
          featured: {
            eq: true
          }
        }
      }
      sort: {
        fields: data___title___text
      }
    ) {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            image {
              alt
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          uid
        }
      }
    }

    allPrismicService {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            description {
              text
            }
            hero_image {
              alt
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicHomePage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }
        hero_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        benefits_heading {
          text
        }
        benefits_description {
          html
        }
        benefits_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        about_heading {
          text
        }
        about_description {
          html
        }
        about_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        pests_heading {
          text
        }
        pests_description {
          html
        }

        termites_heading {
          text
        }
        termites_description {
          html
        }
        termites_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        services_heading {
          text
        }
        services_description {
          html
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default IndexPage;
